
import Vue from 'vue'
import AccessPhoneRepair from '@/components/pages/whitelistID/components/account/components/access-repair/accessPhoneRepair/index.vue'
import FileUpload from '@/components/global/fileUpload/index.vue'
import AccessRepairQuestionnaire from '@/components/pages/whitelistID/components/account/components/access-repair/accessRepairQuestionnaire/index.vue'
import { mapGetters } from 'vuex'
import { capitalizeFirstLetter } from '@/utils/strings'
import { AccessRepairStatuses, LevelName, LevelStatuses } from '@/components/pages/whitelistID/components/account/components/kyc/types'

export default Vue.extend({
  name: 'AccessRepair',
  data: () => ({
    recoveryId: '',
    phoneVerified: false,
    questionnaireValid: false,
    questionnaireValues: {},
    resetMethodTypes: [],
    resetMethod: '',
    isAddingNew: false,
    kycBasic: false,
    accessRecoveryData: {
      email: '',
      status: '',
      loginMethod: '',
      confirmationMethod: '',
      reason: ''
    },
    form: {
      valid: false,
      isLoading: false,
      fields: {
        file: {
          value: null
        },
        comment: {
          value: ''
        }
      }
    }
  }),
  async created () {
    this.initRepair()
  },
  computed: {
    ...mapGetters('user', ['getIsAuthenticated', 'getUserData']),
    formValid () {
      const mainBodyValidation = this.kycBasic ? this.questionnaireValid : this.form.fields.file.value
      return this.resetMethod === 'sms' ? (this.phoneVerified && mainBodyValidation) : mainBodyValidation
    }
  },
  methods: {
    errorHandler (errors: Array<string>) {
      const vm = this as any
      this.$router.push({ name: 'SignIn' })
      this.$store.commit('popupModule/setClosed')
      this.$store.dispatch('alertsBus/openAlert', {
        title: vm.$t('popups.titles.error'),
        type: 'error',
        autoDelete: true,
        content: errors?.join('\n') ?? vm.$t('common.errors.cantDoOperation')
      })
    },
    async initRepair () {
      this.recoveryId = this.$route.query.recoveryId
      this.resetMethod = this.$route.query.secondFactor
      const sessionId = this.$route.query.an3K8QkkJb3Mzz

      this.isAddingNew = Boolean(this.getUserData?.verificationRights?.currentRecovery) ?? false

      if (this.recoveryId) {
        this.getAccessInfo(this.recoveryId)
        return
      }

      const initResponse = await this.$services.authorization.user.accessRepairInit({ secondFactor: this.resetMethod, sessionId })

      if (!initResponse.success) {
        this.errorHandler(initResponse.data.errors)
        return
      }

      this.recoveryId = initResponse.data.accessRecoveryId
      this.$router.replace({ query: { recoveryId: this.recoveryId, an3K8QkkJb3Mzz: sessionId, secondFactor: this.resetMethod } })
      this.getAccessInfo(this.recoveryId)
    },
    infoContent (isPhone?: boolean) {
      let method = this.resetMethod === 'sms' ? this.$t('common.SMS') : this.resetMethod === 'yubikey' ? capitalizeFirstLetter(this.resetMethod) : 'Google Authenticator'
      if (isPhone) method = this.$t('common.phoneNumber')

      let title = this.accessRecoveryData.loginMethod === this.resetMethod ? this.$t('accessRepair.blockAfterRepair', { method }) : ''
      const blockWithResetToSMS = this.resetMethod === this.accessRecoveryData.confirmationMethod && !isPhone
      const mainContent = blockWithResetToSMS ? this.$t('popups.content.operationConfirmationWarning') : this.$t('popups.content.confirmOperationWillNotAble')

      if (!title && this.resetMethod === 'ga') {
        title += this.$t('accessRepair.blockAfterRepair', { method })
      }
      return title ? `${title}
      ${mainContent}` : mainContent
    },
    async getAccessInfo (recoveryId: string) {
      const accessInfoResponse = await this.$services.authorization.user.getAccessInfo({ recoveryId })
      const redirect = accessInfoResponse.data.status === LevelStatuses.success || accessInfoResponse.data.status === LevelStatuses.rejected
      if (redirect) this.$router.push({ name: 'SignIn' })
      if (!accessInfoResponse.success) this.errorHandler(accessInfoResponse.data.errors)
      this.accessRecoveryData = accessInfoResponse.data
      accessInfoResponse.data.levelName === LevelName.basic ? this.kycBasic = true : this.kycBasic = false
      this.resetMethodTypes = accessInfoResponse.data.startedRecoveryTypes
      this.resetMethod = this.resetMethodTypes[this.resetMethodTypes.length - 1]
      const currentRecoveryTypes = accessInfoResponse.data.currentRecoveryTypes
      this.isAddingNew = (!currentRecoveryTypes.includes(this.resetMethod))
      if (this.resetMethodTypes.length > 1 && this.isAddingNew) this.accessRecoveryData.status = LevelStatuses.start
    },
    async resetAccess () {
      const formData = new FormData()

      const files = this.form.fields.file.value
      let comment = this.form.fields.comment.value
      let answers = 'Новых ответов нет'

      if (this.kycBasic) {
        if (this.accessRecoveryData.status !== 'repeat') {
          comment = this.questionnaireValues.comment
          answers = this.questionnaireValues.answers
        }
      } else {
        formData.append('files', files)
      }

      formData.append('comment', comment)
      formData.append('answers', answers)

      this.form.isLoading = true
      const serviceMethod = this.kycBasic ? 'resetAccessBasic' : 'resetAccess'
      const resetAccessResponse = await this.$services.authorization.user[serviceMethod]({ recoveryId: this.recoveryId, payload: formData })
      this.form.isLoading = false
      if (!this.getIsAuthenticated) {
        await this.getAccessInfo(this.recoveryId)
        if (resetAccessResponse.success) this.accessRecoveryData.status = AccessRepairStatuses.work
        return
      }

      this.$store.dispatch('user/loadUser')
      this.$store.commit('popupModule/setClosed')
      this.$router.push({ name: 'whitelistID', query: { recoveryId: this.recoveryId } })
      let typeMethodText = ''
      switch (this.resetMethod) {
        case 'sms':
          typeMethodText = this.$('accessRepair.phoneTypeA')
          break
        case 'ga':
          typeMethodText = 'GA'
          break
        case 'yubikey':
          typeMethodText = 'Yubikey'
          break
      }
      const vm = this as any
      this.$store.dispatch('alertsBus/openAlert', {
        title: vm.$t('alerts.titles.info'),
        type: resetAccessResponse.success ? 'info' : 'error',
        autoDelete: true,
        content: resetAccessResponse.success ? vm.$t('alerts.content.appealAccepted', { typeMethodText }) : resetAccessResponse.data.errors.join('')
      })
    },
    successText () {
      const result = this.resetMethodTypes.map((elName: string) => {
        return this.getContentText(elName)
      }).join(', ')
      return this.$t('accessRepair.appealOnConsideration', { result })
    },
    getContentText (methodType: string) {
      const isLoginMethod = methodType === this.accessRecoveryData.loginMethod
      const isOperation = methodType === this.accessRecoveryData.confirmationMethod
      const formattedName = methodType === 'sms' ? this.$t('common.SMS') : methodType === 'ga' ? 'Google Authenticator' : 'Yubikey'
      const text = isLoginMethod ? this.$t('accessRepair.repairAccessVia', { formattedName }) : (isOperation ? this.$t('accessRepair.reset2FAVia', { formattedName }) : this.$t('accessRepair.resetVia', { formattedName }))
      return text
    }
  },
  components: {
    AccessPhoneRepair,
    FileUpload,
    AccessRepairQuestionnaire
  }
})
